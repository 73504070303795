import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const TheImportanceOfMentorship = () => (
  <Layout
    title="The Importance Of Mentorship"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">The Importance Of Mentorship</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">The Importance Of Mentorship</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../why-we-love-science" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <div className="videoWrapper">
                <Video
                  guidSrc="3b4e0f22-7c81-4051-a1e2-94c66578a8e8"
                  className="de-vid"
                ></Video>
                <Link to="../shaping-futures" className="next">
                  <Icon name="arrowrightgray" />
                </Link>
              </div>
            </div>
            <h3>
              Mentorship is important for all scientists and the cornerstone of
              The 3M Young Scientist Challenge.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default TheImportanceOfMentorship;
